(function () {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('FileNetService', FileNetService);

    FileNetService.$inject = ['$resource', 'GLOBAL_URL'];

    function FileNetService ($resource,GLOBAL_URL) {
        var service = $resource(GLOBAL_URL+'api/file-net/:method',{method:'@method'}, {
        	'returnValue': {
                 method: 'GET',
                 transformResponse: [function (data) {
                     return  { response: data };
                 }],
                 transformResponseError: function(error){
                 if(error.status === 0){
                 	alert('There is a problem connecting to the server. Is the server probably down?!');
                 }
                    return $q.reject(error);
                 }
            }
        });

        return service;
    }
})();
