(function() {
    /*jshint camelcase: false */
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$localStorage', 'Base64', 'GLOBAL_URL', '$window'];

    function AuthServerProvider ($http, $localStorage, Base64, GLOBAL_URL, $window) {
        var service = {
            getToken: getToken,
            login: login,
            logout: logout,
            verifyCaptcha: verifyCaptcha
        };

        return service;

        function getToken () {
            return $localStorage.authenticationToken;
        }

        function login (credentials) {
            var data = 'username=' +  encodeURIComponent(credentials.username) + '&password=' +
                encodeURIComponent(credentials.password) + '&grant_type=password&scope=read%20write&' +
                'client_secret=NNddeZyK&client_id=prugreat' + '&captcha=' + credentials.captcha;

            return $http.post(GLOBAL_URL + 'oauth/token', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': 'Basic ' + Base64.encode('prugreat' + ':' + 'NNddeZyK')
                }
            }).then(authSucess);

            function authSucess (response) {
            	var data = response.data;
                var expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + data.expires_in);
                data.expires_at = expiredAt.getTime();
                $localStorage.authenticationToken = data;
                return response;
            }
        }

        function logout () {
            $http.post(GLOBAL_URL + 'api/logout').then(function() {
                delete $localStorage.authenticationToken;
            });
        }
        
        function verifyCaptcha(captcha) {
            return $http.post(GLOBAL_URL + 'captcha/verify', captcha).then(function (response) {
                return response;
            });      	
        }
    }
})();
