(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'GLOBAL_URL'];

    function stateConfig($stateProvider, $urlRouterProvider, GLOBAL_URL) {
    	$urlRouterProvider.otherwise("/login");
        $stateProvider.state('login', {
        	//parent: 'app',
            url: '/login',
            data: {
                pageTitle: 'Đăng nhập'
            },
            views: {
                'content@': {
                    templateUrl: GLOBAL_URL + 'partial/login',
                    controller: 'LoginController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('forgetPass', {
        	//parent: 'app',
            url: '/forgetPass',
            data: {
                pageTitle: 'Quên mật khẩu'
            },
            views: {
                'content@': {
                    templateUrl: GLOBAL_URL + 'partial/forgetPass',
                    controller: 'ForgetController',
                    controllerAs: 'vm'
                }
            },
        })
        .state('resetPass', {
        	//parent: 'app',
            url: '/resetPass/:userName',
            params: {userName: {squash: true, value: null}},
            data: {
                pageTitle: 'Cấp lại mật khẩu'
            },
            views: {
                'content@': {
                    templateUrl: GLOBAL_URL + 'partial/resetPass',
                    controller: 'ForgetController',
                    controllerAs: 'vm'
                }
            },
        })
    	.state('must_change_pass',{
			url : '/changePass',
			params: {userNameRequired: {squash: true, value: null}, userName: {squash: true, value: null}},
			data : {
				pageTitle : 'Thay đổi mật khẩu'
			},
			views : {
				'content@' : {
					templateUrl : GLOBAL_URL + 'partial/changePass',
					controller : 'AccountInfoController',
					controllerAs : 'vm'
				}
			}
		})
    	.state('not-found',{
			url : '/not-found',
			params: {userNameRequired: {squash: true, value: null}, userName: {squash: true, value: null}},
			data : {
				pageTitle : ''
			},
			views : {
				'content@' : {
					templateUrl : GLOBAL_URL + 'partial?uri=not-found'
				}
			}
		})		
    	.state('access-denied',{
			url : '/access-denied',
			params: {userNameRequired: {squash: true, value: null}, userName: {squash: true, value: null}},
			data : {
				pageTitle : ''
			},
			views : {
				'content@' : {
					templateUrl : GLOBAL_URL + 'partial?uri=access-denied'
				}
			}
		})      
    }
})();
