(function() {
	'use strict';

	angular.module('accountApp').config(stateConfig);
	
	stateConfig.$inject = [ '$stateProvider', '$urlRouterProvider',
			'GLOBAL_URL' ];

	function stateConfig($stateProvider, $urlRouterProvider, GLOBAL_URL) {
		$urlRouterProvider.otherwise("/accountInfo");
		$stateProvider
				.state(
						'accountInfo',
						{
							url : '/accountInfo',
							data : {
								pageTitle : 'Thông tin cá nhân'
							},
							views : {
								'content@' : {
									templateUrl : GLOBAL_URL
											+ 'partial?uri=account/layout/accountInfo',
									controller : 'AccountInfoController',
									controllerAs : 'vm'
								}
							}
						})
				.state(
						'changePass',
						{
							url : '/changePass',
							data : {
								pageTitle : 'Thay đổi mật khẩu'
							},
							views : {
								'content@' : {
									templateUrl : GLOBAL_URL
											+ 'partial?uri=account/layout/changePass',
									controller : 'AccountInfoController',
									controllerAs : 'vm'
								}
							}
						});
	}
})();
