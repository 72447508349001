(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp', [
            'ngStorage', 
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            // Load paging, sort,filter table
	        'ngTable',    
	        'disableAll',
	        'base64',
            'vcRecaptcha'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope', 'FORMAT_DATE', 'TRANSACTION_TYPE'];

    function run(stateHandler, $rootScope, FORMAT_DATE, TRANSACTION_TYPE) {
        stateHandler.initialize();
        $rootScope.FORMAT_DATE = FORMAT_DATE;
        $rootScope.TRANSACTION_TYPE = TRANSACTION_TYPE;
    }
    
    
    

})();
