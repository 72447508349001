/* ---------------------------------------------------------------------------- */
/* ------------------------------------display menu---------------------------- */
/* ---------------------------------------------------------------------------- */
$(document).ready(function () {

    $(".menu-icon").click(function () {
        if ($("#menu").css("display") == "none") {
            $("#menu").show("fast");
        } else {
            $("#menu").hide("fast");
        }
    });
    //Document Click
    $("#menu").mouseup(function () {
        return false;
    });
    //Document Click
    $("#content").mouseup(function () {
        $("#menu").hide();
    });
});

/* ---------------------------------------------------------------------------- */
/* -----------------------------------Open------------------------------------- */
/* ---------------------------------------------------------------------------- */
$(document).ready(function () {
	setTimeout(function(){
		$(".open-full").click(function () {
	        $(this).parent().toggleClass("full")
	        if ($(this).parent().css("position") == "fixed") {
	            $(this).find(">i").html("&#xE5D1;")
	        } else {
	            $(this).find(">i").html("&#xE5D0;")
	        }
	    });
	}, 1000)
});
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        if ($(".open-full").parent().css("position") == "fixed") {
            $(".open-full").parent().toggleClass("full")
        }
    }
    if (e.keyCode == 13 && e.shiftKey) {
        $(".open-full").parent().toggleClass("full")
        if ($(".open-full").parent().css("position") == "fixed") {
            $(".open-full").find(">i").html("&#xE5D1;")
        } else {
            $(".open-full").find(">i").html("&#xE5D0;")
        }
    }
});

/* ---------------------------------------------------------------------------- */
/* ---------------------------------Drag and drop------------------------------ */
/* ---------------------------------------------------------------------------- */
function allowDrop(ev) {
    ev.preventDefault();
}

function drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
}

/* ---------------------------------------------------------------------------- */
/* ---------------------------------Other functions---------------------------- */
/* ---------------------------------------------------------------------------- */
$(document).ready(function () {
    $("#op-pers").click(function () {
        $("#enterprise").hide()
    });
    $("#op-enterp").click(function () {
        $("#enterprise").show()
    });

    $("#op-types").change(function () {
        if ($(this).val() == "1") {
            $(".new-contract").show()
            $(".receipt").hide()
        }
        else if ($(this).val() == "2") {
            $(".new-contract").hide()
            $(".receipt").show()
        }
        else {
            $(".new-contract").hide()
            $(".receipt").hide()
        }
    });
    /*$(document).mouseup(function () {
        $(".alert").hide();
    });*/
    /*For search*/
    /*$(".search-more").mouseup(function () {
            if ($("#search-option").css("display") == "none") {
                $("#search-option").show();
                $(this).html("Thu gọn");
            } else {
                $("#search-option").hide();
                $(this).html("Thêm lựa chọn");
            }
        }
    );*/
    // For draft contract
    $("#op-group").click(function () {
        $("#per").hide()
        $("#group").show()
    });
    $("#op-per").click(function () {
        $("#group").hide()
        $("#per").show()
    });
});
/* ---------------------------------------------------------------------------- */
/* -----------------------------------Scroll to top---------------------------- */
/* ---------------------------------------------------------------------------- */
$(document).ready(function () {

    //Check to see if the window is top if not then display button
    $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
            $('.scrollToTop').fadeIn();
        } else {
            $('.scrollToTop').fadeOut();
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 800);
        return false;
    });
});

function InitPage(){
	$(".open-full").click(function () {
        $(this).parent().toggleClass("full")
        if ($(this).parent().css("position") == "fixed") {
            $(this).find(">i").html("&#xE5D1;")
        } else {
            $(this).find(">i").html("&#xE5D0;")
        }
    });	

	$(document).keyup(function (e) {
	    if (e.keyCode == 27) {
	        if ($(".open-full").parent().css("position") == "fixed") {
	            $(".open-full").parent().toggleClass("full")
	        }
	    }
	    if (e.keyCode == 13 && e.shiftKey) {
	        $(".open-full").parent().toggleClass("full")
	        if ($(".open-full").parent().css("position") == "fixed") {
	            $(".open-full").find(">i").html("&#xE5D1;")
	        } else {
	            $(".open-full").find(">i").html("&#xE5D0;")
	        }
	    }
	});  
	
}