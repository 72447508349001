(function() {
  angular.module("corePRUGAGreatApp").directive("trackedTableRow", trackedTableRow);

  trackedTableRow.$inject = [];

  function trackedTableRow() {
    return {
      restrict: "A",
      priority: -1,
      require: ["^trackedTable", "ngForm"],
      controller: trackedTableRowController
    };
  }

  trackedTableRowController.$inject = ["$attrs", "$element", "$parse", "$scope"];

  function trackedTableRowController($attrs, $element, $parse, $scope) {
    var self = this;
    var row = $parse($attrs.trackedTableRow)($scope);
    var rowFormCtrl = $element.controller("form");
    var trackedTableCtrl = $element.controller("trackedTable");

    self.isCellDirty = isCellDirty;
    self.setCellDirty = setCellDirty;
    self.setCellInvalid = setCellInvalid;

    function isCellDirty(cell) {
      return trackedTableCtrl.isCellDirty(row, cell);
    }

    function setCellDirty(cell, isDirty) {
      trackedTableCtrl.setCellDirty(row, cell, isDirty)
    }

    function setCellInvalid(cell, isInvalid) {
      trackedTableCtrl.setCellInvalid(row, cell, isInvalid)
    }
  }
})();