(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .config(httpConfig)
        .config(timezoneConfig);

    httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];
    timezoneConfig.$inject = ['$provide'];
    
    function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {
        
        //enable CSRF
        $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';
        var d = new Date();
		var tz = d.toString().split("GMT")[1].split(" (")[0]; // timezone, i.e. -0700
		$httpProvider.defaults.headers.common['Z-TIMEZONE'] = tz;
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');

        $httpProvider.interceptors.push('messagesHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here

        $urlMatcherFactoryProvider.type('boolean', {
            name : 'boolean',
            decode: function(val) { return val === true || val === 'true'; },
            encode: function(val) { return val ? 1 : 0; },
            equals: function(a, b) { return this.is(a) && a === b; },
            is: function(val) { return [true,false,0,1].indexOf(val) >= 0; },
            pattern: /bool|true|0|1/
        });
    }
    
    function timezoneConfig($provide) {
        var DEFAULT_TIMEZONE = 'GMT+7';

        $provide.decorator('dateFilter', ['$delegate', '$injector', function($delegate, $injector) {
          var oldDelegate = $delegate;

          var standardDateFilterInterceptor = function(date, format, timezone) {
            if(angular.isUndefined(timezone)) {
              timezone = DEFAULT_TIMEZONE;
            }
            return oldDelegate.apply(this, [date, format, timezone]);
          };

          return standardDateFilterInterceptor;
        }]);
   };
    
})();
