(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'GLOBAL_URL', '$window', 'AlertService', 'AuthServerProvider', '$cookies', 'LDAP_MSG','AccountActive','AccountModify', '$scope', '$uibModal'];

    function LoginController ($rootScope, $state, $timeout, Auth, GLOBAL_URL, $window, AlertService, AuthServerProvider, $cookies, LDAP_MSG, AccountActive,AccountModify, $scope, $uibModal) {
        var vm = this;
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.login = login;
        vm.reloadCaptcha = reloadCaptcha;
        vm.password = null;
        vm.rememberMe = false;
        vm.username = null;
        AlertService.clear();
        vm.captchaURL = GLOBAL_URL + 'captcha.jpg';
        vm.captcha = null;
        vm.captchaError = false;

        $timeout(function (){angular.element('#username').focus();});
        
        function reloadCaptcha() {
            window.grecaptcha.reset();
            vm.captcha = null;
        }

        function cancel() {
            vm.username = null;
            vm.password = null,
            vm.rememberMe = false;
            vm.authenticationError = false;
            vm.captchaError = false;
        }

        function showSessionDialog() {
        	var modalInstance = $uibModal.open({
			      animation: true,
			      templateUrl: GLOBAL_URL
					+ 'partial?uri=login/login-dialog',
			      controller: 'LoginDialogController as vm',
			      size: 'md',
			      backdrop: 'static'
			    });

				 modalInstance.result.then(function (selectedItem) {
					 if(selectedItem == '0') {
						 Auth.logout();
						 $state.transitionTo($state.current, {}, {
				  			  reload: true, inherit: false, notify: true
				  			});
					 } else {
						 $window.open(GLOBAL_URL + 'task', '_self');
					 }
				    }, function () {
				    });
        }

        function login(event) {
            //check login
            $rootScope.loading = true;
            vm.authenticationError = false;
            vm.captchaError = false;
            event.preventDefault();
            AccountActive.get({username:vm.username},function(result){
                if(result.data != null && result.data != '' && result.data != undefined ){
                     $rootScope.loading = false;
                    }else{
                        Auth.login({
                            username: vm.username,
                            password: vm.password,
                            rememberMe: vm.rememberMe,
                            captcha: vm.captcha
                        }).then(function (response) {
                            vm.authenticationError = false;
                            $rootScope.loading = false;
                            var headerCode = response.headers('X-PRUGAGreatApp-ldap-expiration');
                            var errorCode = LDAP_MSG.ERROR[headerCode];
                            if(errorCode){
                                var days = response.headers('X-PRUGAGreatApp-ldap-days');
                                var hours = response.headers('X-PRUGAGreatApp-ldap-hours');
                                var minutes = response.headers('X-PRUGAGreatApp-ldap-minutes');
                                errorCode = errorCode.replace("{0}", days);
                                errorCode = errorCode.replace("{1}", hours);
                                errorCode = errorCode.replace("{2}", minutes);
                                $cookies.put('X-PRUGAGreatApp-ldap-expiration', errorCode);
                            }
                            AccountModify.get(function(result){
                                
                            });
                            var sessionIdentify = response.headers('X-PRUGAGreatApp-session-identify');
                            $cookies.put('X-PRUGAGreatApp-session-identify', sessionIdentify);
                            var sessionCode = response.headers('X-PRUGAGreatApp-session-expired');
                            if(sessionCode == '0') {
                                showSessionDialog();
                            } else {
                                $window.open(GLOBAL_URL + 'task', '_self');
                            }
                        }).catch(function (response) {
                            vm.reloadCaptcha();
                            $rootScope.loading = false;
                            if (response.headers('X-PRUGAGreatApp-ldap-must-change-pass') 
                                    || (!angular.isUndefined(response.data) && response.data !== null && response.data.error === 'unauthorized')) {
                                    $state.go('must_change_pass', {userNameRequired: 'true', userName: vm.username});
                            } else if (response.headers('X-PRUGAGreatApp-session-expired') == '0') {
                                $scope.addAlert('Tài khoản của bạn đang được đăng nhập. Vui lòng liên hệ IT để được hỗ trợ!', 'warning');
                            } else if (!angular.isUndefined(response.data) && response.data !== null
                                    && response.data.error === 'invalid_grant' && response.data.error_description === 'Account is locked') {
                                $scope.addAlert('Tài khoản của bạn đang được đăng nhập. Vui lòng liên hệ IT để được hỗ trợ!', 'warning');
                            } else {
                                vm.authenticationError = true;
                            }
                            vm.username = null;
                            vm.password = null;
                            vm.captcha = null;
                        });
                }
            },function(){
                vm.reloadCaptcha();
                vm.captchaError = true;
                $rootScope.loading = false;
                vm.username = null;
                vm.password = null;
                vm.captcha = null;
            });
        }
    }
})();