(function () {
    'use strict';
    angular
        .module('corePRUGAGreatApp')
        .constant('CONST_COMMON', {
        	'PAYMENT_TYPES': [{code:'CASH',name:'Tiền mặt'},
        	                  {code:'BANK',name:'Ngân hàng'},
        	                  {code:'PO',name:'Bưu điện'}],
            'TYPE_OF_ORGANIZATION': 
            				  [{code:'LHK',name:'Loại hình khác'},
                              {code:'TCTC',name:'Tổ chức tài chính'},
                              {code:'QTK',name:'Quỹ tín thác'}],
            'PROPOSAL_FREQUENCYS':
			            	  [{code:'01',name:'Hàng năm'},
			                  {code:'02',name:'Hàng nửa năm'},
			                  {code:'04',name:'Hàng quý'},
			                  {code:'12',name:'Hàng tháng'},
			                  {code:'00',name:'Phí đơn kỳ'}],
           'RECALCULATE_FREQUENCYS':
			            	  [{code:'01',name:'Hàng năm'},
			                  {code:'02',name:'Hàng nửa năm'},
			                  {code:'04',name:'Hàng quý'},
			                  {code:'12',name:'Hàng tháng'}],
		   'TH2_TH3_FREQUENCYS':
				              [{code:'01',name:'Hàng năm'}],
			'POLICY_LOAN_99':'Hợp đồng có tạm ứng Loan 99. Vui lòng liên hệ GAM phụ trách để được hỗ trợ.',
			'FULLY_PAID':'Hợp đồng đã được đóng đủ phí.'
        })
        .constant('FORMAT_DATE', 'dd/MM/yyyy')
        .constant('FORMAT_DATE_TIME', 'dd/MM/yyyy HH:mm:ss')
        .constant('GLOBAL_STATE', {
			'login': 'login',
			'myTask': 'my-task',
			'commonTask': 'common-task'
		})
		.constant('CLAIM', {
			'CLAIM_STAGE':
				{
				'Registration':'Ðang thẩm định',
				'Confirmation':'Ðang thẩm định',
				'Assessment':'Ðang thẩm định',
				'Approval':'Ðang thẩm định',
				'Closure':'Ðã có quyết định',
				'End':'Ðã có quyết định'
				}
		})
		.constant('WF_TASK', {
			'STATUS':
				{
					'NEW': 'Mới tạo',
					'ASSIGNED' : 'Đã giao'
				},
			'BUS_STATUS':
				{
					'SENT': {
						name :'Đã gửi',
						color :'#ef6502' //cam
					},
					'WAIT_APPROVAL': {
						name :'Đợi duyệt',
						color :'#ef6502' //cam
					},
					'WAIT_PROCESS': {
						name :'Đợi xử lý',
						color :'#ef6502' //cam
					},
					'WAIT_PAY': {
						name :'Đợi chi',
						color :'#ef6502' //cam
					},
					'WAIT_ACCOUNTING_CHECK': {
						name :'Đợi kiểm chứng từ',
						color :'#ef6502' //cam
					},
					'RECEIVED_REQUEST':{
						name :'Nhận yêu cầu',
						color :'#70ed36' //xanh la
					},
					'APPROVAL': {
						name :'Đang duyệt',
						color :'#70ed36' //xanh la
					},
					'PROCESSING': {
						name :'Đang xử lý',
						color :'#70ed36' //xanh la
					},
					'PAYING':{
						name :'Đang chi',
						color :'#70ed36' //xanh la
					},
					'ERROR': {
						name :'Lỗi',
						color :'#ed8e8e' //do
					},
					'CANCELED': {
						name :'Đã hủy',
						color :'#7a6c6c' //xam
					},
					'ERROR_PAY':{
						name :'Lỗi chi',
						color :'#ed8e8e' //do
					},
					'ERROR_VER':{
						name :'Lỗi giới hạn quyền',
						color :'#ed8e8e' //do
					},
					'ERROR_APPROVAL':{
						name :'Lỗi duyệt',
						color :'#ed8e8e' //do
					},
					'SUCCESS':{
						name :'Hoàn tất',
						color :'#0000ff' //xanh duong
					},
					'FINISH':{
						name :'Nhập yêu cầu ý kiến',
						color :'#7a6c6c' //xam
					},
					'WAIT_LIFE':{
						name : 'Hệ thống đang xử lý',
						color :'#70ed36' //xanh la
					},
					'ERROR_CANCEL':{
						name :'Lỗi hủy giao dịch',
						color :'#ed8e8e' //do
					},
					'SUCCESS_OPINION':{
						name :'Hoàn tất',
						color :'#0000ff' //do
					},
					'PS_SUCCESS':{
						name :'Chuyển PS',
						color :'#0000ff' //do
					},
					'DS_SUCCESS':{
						name :'Chuyển CS',
						color :'#0000ff' //do
					}
				}
		})
		.constant('TRANSACTION_TYPE', [
			{
				code: '100',
				name: 'Thu phí hồ sơ mới',
				enable: true
			},
			{
				code: '200', 
				name: ' Thu phí & hoàn trả HĐ',
				enable: true
			},			
			{
				code: '300', 
				name: 'Tạo hợp đồng dự thảo',
				enable: true
			},
			{
				code: '400', 
				name: 'Kiểm tra chứng từ chi trả',
				enable: false
			},
			{
				code: '500', 
				name: 'Chi trả',
				enable: true
			},
			{
				code: '600', 
				name: 'Đăng ký giải quyết QLBH',
				enable: true
			},			
			{
				code: '610', 
				name: 'Bổ sung thông tin QLBH',
				enable: true
			},
			{
				code: '700', 
				name: 'Yêu cầu/ Ý kiến',
				enable: true
			},
			{
				code: '800', 
				name: 'Thay đổi thông tin liên hệ hợp đồng',
				enable: true
			},
			{
				code: '900', 
				name: 'Điều chỉnh/ bổ sung người thụ hưởng',
				enable: true
			},
			{
				code: '1000', 
				name: 'Điều chỉnh định kỳ đóng phí',
				enable: true
			},
			{
				code: '1100', 
				name: 'Điều chỉnh chi tiết nhân thân',
				enable: true
			},
			{
				code: '1200', 
				name: 'Quan hệ nhân thân (retention)',
				enable: true
			},
			{
				code: '1300', 
				name: 'Cứu hủy hợp đồng (retention)',
				enable: true
			},
			{
				code: '1500', 
				name: 'Thay đổi thông tin liên hệ hợp đồng (Thư trả về)',
				enable: true
			},
			{
				code: '1600', 
				name: 'Đăng ký tài khoản đăng nhập PruOnline',
				enable: true
			},
			{
				code: '1700', 
				name: 'Khôi phục hiệu lực hợp đồng (HĐ mất hiệu lực từ ngày D61 - D70)',
				enable: true
			},
			{
				code: '1800', 
				name: 'Điều chỉnh hợp đồng (Hủy hợp đồng trong thời gian cân nhắc)',
				enable: true
			},
			{
				code: '1900', 
				name: 'Đăng ký chữ ký mẫu của bên mua bảo hiểm',
				enable: true
			},
			{
				code: '2000', 
				name: 'Khôi phục hiệu lực hợp đồng (<6 tháng)',
				enable: true
			},
			{
				code: '2100', 
				name: 'Điều chỉnh định kỳ đóng phí',
				enable: true
			}
			
		])
		.constant('EVENT', {
			'changeOffice': 'CHANGE_OFFICE'
		})
        .constant('LDAP_MSG', {
        	ERROR: {
        		"msg.time.before.expiration": "Tài khoản còn {0} ngày, {1} giờ, {2} phút nữa hết hạn!"
        	}
        })
        .constant('FILE_UPLOAD_ALLOW','!.exe,!.sh,!.bat')
        .constant('QUESTIONNAIRE_8', [
			{
				code: '0000',
				name: 'Chụp mạch máu - angiogram'
			},
			{
				code: '0022', 
				name: 'Sinh thiết - giải phẫu bệnh - biopsy'
			},			
			{
				code: '0044', 
				name: 'Thử máu - xét nghiệm máu - blood test'
			},
			{
				code: '0066', 
				name: 'Chụp cắt lớp - ct scan'
			},
			{
				code: '0088', 
				name: 'Nội soi đại trực tràng'
			},
			{
				code: '0110', 
				name: 'Điện tim - điện tâm đồ - resting ecg'
			},			
			{
				code: '0132', 
				name: 'Điện tâm đồ gắng sức - exercise ecg - treadmill'
			},
			{
				code: '0154', 
				name: 'Nội soi dạ dày'
			},
			{
				code: '0176', 
				name: 'Chụp cộng hưởng từ mri'
			},
			{
				code: '0198', 
				name: 'Xét nghiệm pap'+"'"+' - pap'+"'"+'s smear'+"'"
			},
			{
				code: '0220', 
				name: 'Siêu âm'
			},
			{
				code: '0242', 
				name: 'Xn nước tiểu - tổng phân tích nước tiểu'
			},
			{
				code: '0264', 
				name: 'Chụp x quang tim phổi - x quang ngực thẳng'
			},
			{
				code: '0286', 
				name: 'Chụp x quang khác (không phải x quang phổi)'
			},
			{
				code: '0308', 
				name: 'Các xét nghiệm khác'
			}
		])
    .constant('QUESTIONNAIRE_5', [
  			{
  				code: '1',
  				name: 'Mổ sinh, mổ ruột thừa, mổ thai ngoài tử cung, mổ cắt túi mật do sỏi, gãy xương không thương tật.'
  			},
  			{
  				code: '2', 
  				name: 'Mổ những bệnh khác'
  			}
  		])
    .constant('RELATIONSHIP_TYPE', [
			{
				code: 'BMBH',
				name: 'Bên mua BH'
			},
			{
				code: 'NDBHC', 
				name: 'NĐBH chính'
			},
			{
				code: 'NDBHBS1', 
				name: 'NĐBHBS-1'
			},
			{
				code: 'NDBHBS2', 
				name: 'NĐBHBS-2'
			},
			{
				code: 'NDBHBS3', 
				name: 'NĐBHBS-3'
			},
			{
				code: 'KHAC', 
				name: 'Khác'
			}
		])
	.constant('RIGHTS_EDUCATION', [
			{
				code: 0,
				name: 'Kế hoạch linh hoạt'
			},
			{
				code: 1, 
				name: 'Kế hoạch tuổi hưu'
			},
			{
				code: 2, 
				name: 'Kế hoạch học vấn'
			}
		])
		.constant('PAPER_TYPE',[{"code":"10213131","id":null,"name":"Bảng phân tích nhu cầu tài chính (FNA)"},{"code":"10307111","id":null,"name":"Phiếu thẩm định tài chính"},{"code":"10205181","id":null,"name":"Phiếu thu kỳ phí đầu tiên"},{"code":"10205191","id":null,"name":"Chứng từ đóng phí qua đối tác (ngân hàng, bưu điện ...)"},{"code":"10205171","id":null,"name":"Hình chụp khách hàng"},{"code":"10205991","id":null,"name":"Các giấy tờ khác"},{"code":"10205111","id":null,"name":"Danh sách thành viên"},{"code":"10205141","id":null,"name":"Giấy chứng nhận đăng ký kết hôn / ly dị"},{"code":"10205121","id":null,"name":"Giấy chứng tử"},{"code":"10205021","id":null,"name":"Giấy khai sinh"},{"code":"10205091","id":null,"name":"Giấy phép kinh doanh &  giấy phép thành lập"},{"code":"10307111","id":null,"name":"Giấy tờ chứng minh thu nhập và tài chính"},{"code":"10307091","id":null,"name":"Giấy xác định nghề nghiệp"},{"code":"10205101","id":null,"name":"Tờ khai chi tiết bên mua Bảo Hiểm"},{"code":"10205031","id":null,"name":"Hộ chiếu / Visa"},{"code":"10205131","id":null,"name":"Hộ khẩu / Giấy tạm trú"},{"code":"10307061","id":null,"name":"Hồ sơ điều trị bệnh / Giấy tờ khám sức khỏe"},{"code":"10205151","id":null,"name":"Quyết định công nhận giám hộ hợp pháp"},{"code":"10205081","id":null,"name":"Thẻ bảo hiểm y tế"},{"code":"10205041","id":null,"name":"Thẻ Đảng viên"},{"code":"10205071","id":null,"name":"Thẻ hưu trí"},{"code":"10205051","id":null,"name":"Thẻ ngành"},{"code":"10205161","id":null,"name":"Thư chấp thuận của Cha Mẹ hoặc người giám hộ hợp pháp"},{"code":"10205061","id":null,"name":"Văn bằng / Bằng lái xe"},{"code":"10213061","id":null,"name":"Khảo sát rủi ro -  Kế hoạch tài chính"}]);

})();