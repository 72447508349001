(function() {
    'use strict';

   /* var jhiAlert = {
        template: '<div class="alerts" ng-cloak="">' +
                        '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
                            '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre ng-bind-html="alert.msg"></pre></uib-alert>' +
                        '</div>' +
                  '</div>',
        controller: jhiAlertController
    };*/
    /*
     * TaiLTA customize
     */
    var jhiAlert = {
    		template:
    	        '<div id="toast-container" class="toast-top-right">' +
    	            '<div id="divAlert" ng-repeat="alert in $ctrl.alerts" ng-class="\' toast-\' + (alert.type || \'warning\')" ng-click="alert.close($ctrl.alerts)" ng-mouseover="stopTimer(alert)"  ng-mouseout="restartTimer(alert)">' +
    	              '<button class="toast-close-button" ng-click="alert.close($ctrl.alerts)">&times;</button>' +
    	              '<div class="toast-title">Thông báo</div>' +
    	              '<div class="toast-message">' +
    	                '<div >{{alert.msg}}</div>' +
    	              '</div>' +
    	            '</div>' +
    	        '</div>',
           /* template: '<div style="width:100%;">' +
            	'<div uib-alert ng-repeat="alert in $ctrl.alerts" ng-cloak="" ng-class="[alert.position, {\'toast\': alert.toast}, \'alert-\' + (alert.type || \'warning\')]" close="alert.close($ctrl.alerts)"><span ng-bind-html="alert.msg"></span></div>' +
            	'</div>',*/
            controller: jhiAlertController
        };
    angular
        .module('corePRUGAGreatApp')
        .component('jhiAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService'];

    function jhiAlertController($scope, AlertService) {
        var vm = this;
        vm.alerts = AlertService.get();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
