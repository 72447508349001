(function() {
    'use strict';
    angular
        .module('corePRUGAGreatApp')
        .factory('CustomerService', CustomerService);

    CustomerService.$inject = ['$resource', 'DateUtils' ,'$q'];

    function CustomerService ($resource, DateUtils, $q) {
    	
        return $resource('../../api/customer/:uri',{uri: '@uri'}, {
            'getCustomers': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                transformResponseError: function(error){
                	if(error.status === 0){
                		alert('There is a problem connecting to the server. Is the server probably down?!');
                	}
                	 return $q.reject(error);
                }
            },
            'getCustomer': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                transformResponseError: function(error){
                	if(error.status === 0){
                		alert('There is a problem connecting to the server. Is the server probably down?!');
                	}
                	 return $q.reject(error);
                }
            },
            'getPolicies': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                transformResponseError: function(error){
                	if(error.status === 0){
                		alert('There is a problem connecting to the server. Is the server probably down?!');
                	}
                	 return $q.reject(error);
                }
            }
        });
    }
})();
