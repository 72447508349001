(function() {
    'use strict';

    angular
        .module('accountApp')
        .factory('AccountInfoService', AccountInfoService);
    
    angular.module('corePRUGAGreatApp').factory('AccountInfoService', AccountInfoService);

    AccountInfoService.$inject = ['$resource', '$http','GLOBAL_URL'];

    function AccountInfoService ($resource, $http, GLOBAL_URL) {
    	return $resource(GLOBAL_URL+'api/account/:uri',{uri: '@uri'}, {
    		  'postList':   {method:'POST', isArray: true},
    		  'post':   {method:'POST'}
        });
    }
})();
