(function() {
    'use strict';
    angular
        .module('corePRUGAGreatApp')
        .directive('format', format)
        .directive('formatAm', formatAm)
        .directive('numberMin', numberMin)
    	.directive('loadingPage', loadingPage)
    	.directive('compareNumber', compareNumber)
    	.directive('autoFocusErrorWhenSubmit', autoFocusErrorWhenSubmit);
    
    formatAm.$inject = ['$filter', '$locale'];
    format.$inject = ['$filter', '$locale'];
    compareNumber.$inject = ['$filter'];    
    
    function format ($filter, $locale) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function postLink(scope, elem, attrs, modelCtrl) {    
            	
            	if (!modelCtrl) return;
            	
            	modelCtrl.$formatters.unshift(function (a) {
                    return $filter(attrs.format)(modelCtrl.$modelValue);
                });
            	
            	modelCtrl.$parsers.unshift(function (viewValue) {
            		
                    var plainNumber = viewValue.replace(/[^\d.]/g, '');
                    elem.val($filter('number')(plainNumber));
                    
                    return plainNumber;
                });
            }
        };
    }
    
   function formatAm ($filter, $locale) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function postLink(scope, elem, attrs, modelCtrl) {    
            	if (!modelCtrl) return;
            		modelCtrl.$formatters.unshift(function (a) {
            			return $filter('number')(modelCtrl.$modelValue);
            		});
            	
            		modelCtrl.$parsers.unshift(function (viewValue) {
            			
            			var plainNumber = viewValue.replace(/[^-\d.]/g, '');
            			elem.val($filter('number')(plainNumber));
            			
            			return plainNumber;
            		});
            		
            	}	
            
        };
    }
    
    function numberMin() {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attributes, ngModel) {
                ngModel.$validators.numberMin = function (modelValue) {
                    if (!isNaN(modelValue) && modelValue !== "" && attributes.numberMin !== "")
                        return parseFloat(modelValue) >= attributes.numberMin;
                    else
                        return true;
                }
            }
        };
    }
    
    function loadingPage() {
    	return {
            restrict: 'E',
            replace:true,
            template: '<div class="loading"><div id="fountainTextG"><div id="fountainTextG_1" class="fountainTextG">P</div><div id="fountainTextG_2" class="fountainTextG">r</div><div id="fountainTextG_3" class="fountainTextG">u</div><div id="fountainTextG_4" class="fountainTextG">d</div><div id="fountainTextG_5" class="fountainTextG">e</div><div id="fountainTextG_6" class="fountainTextG">n</div><div id="fountainTextG_7" class="fountainTextG">t</div><div id="fountainTextG_8" class="fountainTextG">i</div><div id="fountainTextG_9" class="fountainTextG">a</div><div id="fountainTextG_10" class="fountainTextG">l</div><div id="fountainTextG_11" class="fountainTextG">.</div><div id="fountainTextG_12" class="fountainTextG">.</div><div id="fountainTextG_13" class="fountainTextG">.</div></div></div>',
            link: function (scope, element, attr) {
                  scope.$watch('loading', function (val) {
                      if (val)
                          $(element).show();
                      else
                          $(element).hide();
                  });
            }
          }
    }
    
    function compareNumber($filter) {
	   return {
	      require: 'ngModel', 
	      scope: {
	    	  'with': "=with"
	    	 
          },
	      link: function(scope, elem, attr, ngModel) {
	          
	         ngModel.$parsers.unshift(function(value) {
	        	var tempValue = value;
	        	if(tempValue == "")
	        		tempValue = 0;
	        	 var valid = !(parseFloat(scope.with) < 1 && parseFloat(tempValue) < 1);
	        	 
	        	 ngModel.$setValidity('blacklist', valid);
	        	 
	        	 var plainNumber = value.replace(/[^\d.]/g, '');
                 elem.val($filter('number')(plainNumber));
	        	 
	        	 return valid ? plainNumber : undefined;
	          });
	         
	         ngModel.$formatters.unshift(function(value) {
	        	 if(value == null || value == undefined)
	        		 value = 0;
	        	 if(scope.with == null || scope.with == undefined)
	        		 scope.with = 0;
	        	 
	        	  if(parseFloat(scope.with) < 1 && parseFloat(value) < 1){
	 	        	 ngModel.$setValidity('blacklist', false);
	 	         }
	        	 return value;
	          });
	      }
	   };
    }
    
    function autoFocusErrorWhenSubmit() {
    	return {
            scope: true,
            link: function (scope, element, attrs) {

                var form = scope[attrs.name];

                element.bind('submit', function(event) {
                    var field = null;
                    for (field in form) {
                        if (form[field].hasOwnProperty('$pristine') && form[field].$pristine) {
                            form[field].$dirty = true;
                        }
                    }

                    var invalid_elements = element.find('.ng-invalid');
                    if (invalid_elements.length > 0)
                    {
                       invalid_elements[0].focus();
                    }

                    event.stopPropagation();
                    event.preventDefault();
                });
            }
        };    	
    }
        
    
})();

