(function() {
	'use strict';

	angular.module('corePRUGAGreatApp').controller('LoginDialogController',
			LoginDialogController);

	LoginDialogController.$inject = [ '$scope', '$uibModalInstance'];

	function LoginDialogController($scope, $uibModalInstance) {
		var vm = this;
		vm.ok = ok;

		function ok() {
			$uibModalInstance.close(vm.login);
		}
		;

	}
})();
