(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService', '$rootScope', '$cookies'];

    function notificationInterceptor ($q, AlertService, $rootScope, $cookies) {
        var service = {
            response: response
        };

        return service;

        function response (response) {
        	//clear netscaler
        	$cookies.remove('NSC_QSVHSFBUMC');
            var alertKey = response.headers('X-PRUGAGreatApp-alert');
            
            if(angular.isString(alertKey)) {
            	 AlertService.success(alertKey, { param : response.headers('X-PRUGAGreatApp-params')});
            }
            
            if(response != null && response.data != null && response.data.messages !=null){
            
            	$rootScope.$emit('PRUGAGreatApp.httpMessages', response);
            }
            
            return response;
        }
    }
})();
