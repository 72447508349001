(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('ForgetService', ForgetService);

    ForgetService.$inject = ['$resource', '$http','GLOBAL_URL'];

    function ForgetService ($resource, $http, GLOBAL_URL) {
    	return $resource(GLOBAL_URL+'api/forget/:uri',{uri: '@uri'}, {
    		  'postList':   {method:'POST', isArray: true},
    		  'post':   {method:'POST'}
        });
    }
})();
