(function() {
    'use strict';
    angular
        .module('corePRUGAGreatApp')
         .directive('viewCustomer', viewCustomer);

    viewCustomer.$inject = ['$uibModal', 'CustomerService', 'GLOBAL_URL'];
    
    function viewCustomer ($uibModal, CustomerService, GLOBAL_URL) {
        return {
            restrict: "E",
            //require: "ngModel",
            scope: {
            	clientNum : "=clientNum"
            },
            templateUrl : GLOBAL_URL+ "partial?uri=app/components/customer/view/view-customer",
            link: function (scope, element, attributes) {
            	scope.viewCustomer = function() {
            		if(scope.clientNum != ''){
            			CustomerService.getCustomer({uri:'get-customer-by-clientCode',clientCode:scope.clientNum}, function(customer){
            				
            				CustomerService.getPolicies({uri:'get-policies-by-clientCode',clientCode:scope.clientNum}, function(policies){
                    			var modalInstance = 
                					//show dialog
                					$uibModal.open({
                						animation : true,
                						templateUrl : 'customer-dialog.html',
                						controller: 'ViewCustomerController',
                						size : 'lg',
                						resolve: {
                							customer: function () {
                							   return customer;
                			                },
                			                policies: function () {
                			                  return policies;
               			                   }
                			            }
                				});
            	           	}, function (error){
            	           		
            	           	})
            				
                		
        	           	}, function (error){
        	           		
        	           	})
            		}
                }  
            },
            controller : ['$scope', function(scope) {
            
             }],
         
        };
    }
})();

