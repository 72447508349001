(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$state', '$window', 'GLOBAL_URL'];

    function LoginService ($state, $window, GLOBAL_URL) {
        var service = {
            open: open
        };

        return service;

        function open () {
        	$window.open(GLOBAL_URL, '_self');
        }
    }
})();
