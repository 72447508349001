(function() {
    'use strict';
    angular
        .module('corePRUGAGreatApp')
        .factory('CommonService', CommonService);

    CommonService.$inject = ['$resource', 'GLOBAL_URL'];

    function CommonService ($resource, GLOBAL_URL) {
        return $resource(GLOBAL_URL + 'api/masterdata/:masterurl',{masterurl:'@masterurl'}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'returnValue': {
                method: 'GET',
                transformResponse: [function (data) {
                    return  { response: data };
                }],
                transformResponseError: function(error){
                	if(error.status === 0){
                		alert('There is a problem connecting to the server. Is the server probably down?!');
                	}
                	 return $q.reject(error);
                }
            }
        });
    }
})();
