(function() {
    'use strict';

    var jhiAlertMessages = {
        template:
            '<div id="toast-container" class="toast-top-right">' +
                '<div id="divAlert" ng-repeat="alert in $ctrl.alerts" ng-class="\'toast-\' + (alert.type || \'warning\')" >' +
                  '<button class="toast-close-button" ng-click="alert.close($ctrl.alerts)">&times;</button>' +
                  '<div class="toast-title">Thông báo</div>' +
                  '<div class="toast-message">' +
                    '<div >{{alert.msg}} <a href="{{alert.url}}" target="_blank"> <i><u>{{alert.url_context}}</u></i></a> </div>' +
                  '</div>' +
                '</div>' +
            '</div>',
        controller: jhiAlertMessagesController
    };

    angular
        .module('corePRUGAGreatApp')
        .component('jhiAlertMessages', jhiAlertMessages);

    jhiAlertMessagesController.$inject = ['$scope', 'AlertService', '$rootScope', '$window'];

    function jhiAlertMessagesController ($scope, AlertService, $rootScope, $window) {
        var vm = this;

        vm.alerts = [];
        $rootScope.addAlert = addAlert;
        $rootScope.clearMessage = clearMessage;
        function clearMessage() {
            vm.alerts = [];
        }
        function addAlert (message,type, url, url_context,key, data) {
            vm.alerts.unshift(
                AlertService.add(
                    {
                        type: type==undefined?'danger':type,
                        msg: message,
                        url: url,
                        url_context: url_context,
                        timeout : 900000,
                        toast: AlertService.isToast()
                    },
                    vm.alerts
                )
            );
        }

        var cleanHttpErrorListener = $rootScope.$on('PRUGAGreatApp.httpMessages', function (event, httpResponse) {
            //vm.alerts = [];
            
            var i;
            event.stopPropagation();
            switch (httpResponse.status) {
            // connection refused, server not reachable
            /*case 0:
                addAlert('Vui lòng thử lại!');
                break;
            case -1:
                addAlert('Vui lòng thử lại!');
                break;*/
            case 400:
                var errorHeader = httpResponse.headers('X-PRUGAGreatApp-error');
                //var entityKey = httpResponse.headers('X-PRUGAGreatApp-params');
                if (errorHeader) {
                    if(errorHeader === 'las.error' || errorHeader.indexOf('las.error') > 0){
                        angular.forEach(httpResponse.data.messages, function(message) {
                            if(message.message)
                                addAlert(message.message,message.type, null, null);
                        });
                    }else{
                        if(httpResponse.data){
                            angular.forEach(httpResponse.data, function(message) {
                                if(message.message)
                                    addAlert(message.message,message.type, message.message,null);
                            });
                        }

                        if (httpResponse.data && httpResponse.data.fieldMessages) {
                            for (i = 0; i < httpResponse.data.fieldMessages.length; i++) {
                                var fieldMessage = httpResponse.data.fieldMessages[i];
                                if(fieldMessage)
                                    addAlert(fieldMessage.message, fieldMessage.type,null, null);
                            }
                        }
                    }

                } else{
                     if (httpResponse.data) {
                    	if(httpResponse.data.messages != null && httpResponse.data.messages != undefined){
                    		angular.forEach(httpResponse.data.messages, function(message) {
                                if(message.message)
                                    addAlert(message.message,message.type, null, null);
                            });
                    	}else{
                    		angular.forEach(httpResponse.data, function(message) {
                                if(message.message)
                                    addAlert(message.message,message.type, null, null);
                            });
                    	}
                        
                     }
                }
                
                break;

            case 404:
                addAlert('Yêu cầu không tìm thấy!');
                break;
                
            case 200:
                 if (httpResponse.data) {
                    angular.forEach(httpResponse.data.messages, function(message) {
                        if(message.message)
                            addAlert(message.message,message.type, null, null);
                    });
                 }

                break;

            case 500:
                addAlert('Vui lòng thử lại!');
                break;

            default:
                if (httpResponse.data) {
                		angular.forEach(httpResponse.data, function(message) {
                			if(message.message)
                				addAlert(message.message, message.type, message.message,null);
            			});
                } else {
                	addAlert('Vui lòng đăng nhập và thử lại!');
                	$window.open(GLOBAL_URL,'_self');
                }
            }
        });

        $scope.$on('$destroy', function () {
            if(angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null){
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();