(function() {
	'use strict';

	angular.module('corePRUGAGreatApp').controller('SessionDialogController',
			SessionDialogController);

	SessionDialogController.$inject = [ '$scope', '$uibModalInstance'];

	function SessionDialogController($scope, $uibModalInstance) {
		var vm = this;
		vm.ok = ok;

		function ok() {
			$uibModalInstance.close();
		}
		;

	}
})();
