(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('Account', Account)
        .factory('AccountActive', AccountActive)
        .factory('AccountModify', AccountModify);

    Account.$inject = ['$resource', 'GLOBAL_URL'];
    AccountActive.$inject = ['$resource', 'GLOBAL_URL'];
    AccountModify.$inject = ['$resource', 'GLOBAL_URL'];
    function Account ($resource, GLOBAL_URL) {
        var service = $resource(GLOBAL_URL + 'api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'post': {method: 'POST'}
        });

        return service;
    }
    function AccountActive ($resource, GLOBAL_URL) {
        var service = $resource(GLOBAL_URL + 'api/check-inactive', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'post': {method: 'POST'}
        });

        return service;
    }
    function AccountModify ($resource, GLOBAL_URL) {
        var service = $resource(GLOBAL_URL + 'api/update-account',{
        		'get': {method: 'GET'}});

        return service;
    }
})();
