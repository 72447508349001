(function () {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('PolicyService', PolicyService);

    PolicyService.$inject = ['$resource', 'GLOBAL_URL'];

    function PolicyService ($resource) {
        var service = $resource(GLOBAL_URL+'api/ga/policy/:method',{method:'@method'}, {
            'getPolicyByPolicyNum': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                clientIdNum: '@clientIdNum'
            },
            'getPolicyCFI': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });

        return service;
    }
})();
