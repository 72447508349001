(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .controller('ViewCustomerController', ViewCustomerController);

    ViewCustomerController.$inject = ['$scope', '$uibModalInstance', 'customer', 'policies', 'NgTableParams','$window','$base64'];

    function ViewCustomerController ($scope, $uibModalInstance, customer, policies, NgTableParams, $window, $base64) {
    	var customerCtrl = this;
    	$scope.closeModal = function(){
    		$uibModalInstance.dismiss('cancel');
    	}
    	var birthdate ='';
    	if(customer.birthDate != null || customer.birthDate != undefined){
    		var newstr = customer.birthDate.split('-');
       	 if(newstr.length > 0 && newstr.length <= 3){
       		  birthdate= newstr[1] +'/'+newstr[0]+'/'+newstr[2];
       	 	}
    	}
    	$scope.customer = customer;
    	$scope.customer.birthDate = birthdate;
    	$scope.policies = policies;
    	$scope.tableParams = new NgTableParams({
  	      page:  1, // show first page
  	      count: 20 // count per page
  	    }, {
  	      filterDelay: 0,
  	      dataset: policies,
  	      counts: [],
  	    });
    	
    	$scope.$watch('customer.gender ',function(newValue){
    		if(newValue === 'F')
    			$scope.customer.gender = 'Nữ';
    		else if(newValue === 'M')
    			$scope.customer.gender = 'Nam';
    	});
    	
    	$scope.openPolicyInfo = function openPolicyInfo(policyNum) {
    		var uri = null;
    		var number = null;
    		if (policyNum) {
    			uri = 'ga/history/#/constractInfo/';
    			number = $base64.encode(policyNum);
    		}
    		uri = uri.concat(number);
    		openNewTabOrNewWindow(GLOBAL_URL + uri);
    	}
    	// this function can fire onclick handler for any DOM-Element
    	function fireClickEvent(element) {
    	    var evt = new window.MouseEvent('click', {
    	        view: window,
    	        bubbles: true,
    	        cancelable: true
    	    });

    	    element.dispatchEvent(evt);
    	}
    	function openNewTabOrNewWindow(targetURL) {
    	    var a = document.createElement('a');
    	    a.href = targetURL;
    	    a.target = '_blank'; // now it will open new tab/window and bypass any popup blocker!
    	    fireClickEvent(a);
    	}
    }
})();
