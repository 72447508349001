(function() {
	'use strict';

	angular.module('corePRUGAGreatApp').controller('ForgetController',
			ForgetController);

	ForgetController.$inject = [ '$timeout', 'ForgetService', '$state',
			'$http', '$scope', 'GLOBAL_URL', 'authExpiredInterceptor',
			'$base64','AuthServerProvider' ];

	function ForgetController($timeout, ForgetService, $state, $http, $scope,
			GLOBAL_URL, authExpiredInterceptor, $base64,AuthServerProvider) {
		var vm = this;
		var self = $scope;
		vm.captchaURL = GLOBAL_URL + 'captcha.jpg';
		vm.captcha = null;
        vm.captchaError = false;
        vm.reloadCaptcha = reloadCaptcha;
		function reloadCaptcha() {
            vm.captchaURL = GLOBAL_URL + 'captcha.jpg' + '?' + new Date().getTime();
        }
		vm.createNewPass = function() {
			self.loading = true;
			var hashStr = "userName=";
			hashStr += vm.account.userName;
// hashStr += "gagreat_manager";
			hashStr += "&mobile=";
			hashStr += vm.account.phone;
// hashStr += "01672955168";
			hashStr += "&time=";
			hashStr += new Date().getTime();
			var hash = $base64.encode(hashStr);
			vm.captchaError = false;
			AuthServerProvider.verifyCaptcha(vm.captcha).then(function(response) {
                if (response.data) {
			ForgetService
					.post(
							{
								uri : 'createNewPass',
								hashString : hash
							},
							vm.account,
							function(result) {
								$(window).scrollTop(0);
								self.loading = false;
								if (result.messages === null
										|| result.messages.length == 0) {
									self
											.addAlert(
													'Email đã gửi thành công, xin vui lòng kiểm tra lại hộp thư!',
													'success');
								}else{
									 vm.reloadCaptcha();
								}
							},
							function(result) {
								self
										.addAlert(
												'Có lỗi trong quá trình xử lý, chúng tôi đang cố gắng khắc phục.',
												'danger');
								self.loading = false;
							});
                }else {
                vm.reloadCaptcha();
                vm.captchaError = true;
                self.loading = false;
                vm.captcha = null;
            }}).catch(function () {
                vm.reloadCaptcha();
                vm.captchaError = true;
                self.loading = false;
                vm.captcha = null;
            });
		}
		
		vm.success = true;
		vm.resetPass = function() {
			self.loading = true;
			var hash = $base64.decode($state.params.userName);
			ForgetService
					.post(
							{
								uri : 'resetPass'
							}, hash,
							function(result) {
								$(window).scrollTop(0);
								self.loading = false;
								if (result.messages === null
										|| result.messages.length == 0) {
									return;
								} else {
									vm.success = false;
								}
							},
							function(result) {
								self
										.addAlert(
												'Có lỗi trong quá trình xử lý, chúng tôi đang cố gắng khắc phục.',
												'danger');
								vm.success = false;
								self.loading = false;
							});
		}
	}
})();
