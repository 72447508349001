(function() {
	'use strict';

	angular.module('accountApp').controller('AccountInfoController',
			AccountInfoController);
	
	angular.module('corePRUGAGreatApp').controller('AccountInfoController', AccountInfoController);

	AccountInfoController.$inject = [ '$window', '$timeout', '$cookies', 'AccountInfoService',
			'$state', '$http', '$scope', 'GLOBAL_URL', '$base64', '$uibModal' ,'$stateParams', '$injector', 'Auth', 'LDAP_MSG'];

	function AccountInfoController($window, $timeout, $cookies, AccountInfoService, $state, $http, $scope, GLOBAL_URL, 
			$base64, $uibModal, $stateParams, $injector, Auth, LDAP_MSG) {
		var vm = this;
		var self = $scope;
		self.userNameRequired = $stateParams.userNameRequired;
		
		if($stateParams.userName){
			if(vm.account == undefined){
				vm.account = {}
			}
			vm.account.uid = $stateParams.userName;			
		}

		vm.loadPage = function() {
			AccountInfoService.get({
				uri : 'initPage'
			}, function(result) {
				vm.account = result;
			});
		}

		vm.saveCustomerInfo = function() {
			AccountInfoService.post({uri : 'saveCustomerInfo'},
				vm.account,
				function(result) {
					$(window).scrollTop(0);
					if (result.messages === null
							|| result.messages.length == 0) {
						self.addAlert('Cập nhật thông tin cá nhân thành công.','success');
					}
					
				},
			function() {
				self.addAlert('Có lỗi trong quá trình xử lý, chúng tôi đang cố gắng khắc phục.','danger');
			});
		}

		vm.changePass = function() {
			vm.account.mustChangePass = self.userNameRequired;
			AccountInfoService.post({uri : 'changePass'},
				vm.account,
				function(result) {
					$(window).scrollTop(0);
					if (result.messages === null|| result.messages.length == 0) {
						self.addAlert('Cập nhật thông tin cá nhân thành công.','success');
					
						if($stateParams.userNameRequired){ // case pass in history
							Auth.login({
		                        username: vm.account.uid,
		                        password: vm.account.newPass,
		                        rememberMe: false
		                    }).then(function (response) {
		                        var headerCode = response.headers('X-PRUGAGreatApp-ldap-expiration');
		                        var errorCode = LDAP_MSG.ERROR[headerCode];
		                        
		                        if(errorCode){
		                        	var days = response.headers('X-PRUGAGreatApp-ldap-days');
		                        	var hours = response.headers('X-PRUGAGreatApp-ldap-hours');
		                        	var minutes = response.headers('X-PRUGAGreatApp-ldap-minutes');
		                        	
		                        	errorCode = errorCode.replace("{0}", days);
		                        	errorCode = errorCode.replace("{1}", hours);
		                        	errorCode = errorCode.replace("{2}", minutes);
		                        	
		                        	$cookies.put('X-PRUGAGreatApp-ldap-expiration', errorCode);
		                        }
		                    	
		                    	$window.open(GLOBAL_URL + 'login', '_self')
		                        
		                    });							
						}
					}
					
				},
				function(result) {
					self.addAlert('Có lỗi trong quá trình xử lý, chúng tôi đang cố gắng khắc phục.','danger');
				});
		}

		vm.resetCustomerInfo = function() {
			vm.account.email = '';
			vm.account.phone = '';
			vm.account.description = '';
		}

		vm.resetPass = function() {
			vm.account.pass = '';
			vm.account.newPass = '';
			vm.account.passVerify = '';
		}

		var modalInstance = null;
		vm.openPopupChangePass = function() {
			modalInstance = $uibModal
			.open({
				animation : true,
				templateUrl : GLOBAL_URL
				+ 'partial?uri=account/layout/changePass',
				controller : 'AccountInfoController',
				controllerAs : 'vm',
				size : 'lg'
			});
		}
	}
})();
