(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);

    
    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$document', 'GLOBAL_URL', '$window', '$localStorage', '$sessionStorage'];

    function authExpiredInterceptor($rootScope, $q, $injector, $document, GLOBAL_URL, $window, $localStorage, $sessionStorage) {
        var service = {
            responseError: responseError,
            getCSRF: getCSRF,
            getToken: getToken
        };

        return service;

        function responseError(response) {
            // If we have an unauthorized request we redirect to the login page
            // Don't do this check on the account API to avoid infinite loop
        	var to = $rootScope.toState;
        	var Auth = $injector.get('Auth');
        	if (response.status === 401 && to.name != 'login') {
        		if (to.name != 'must_change_pass') {
	            	Auth.logout();
	            	if (to.name != 'login') {
	                    var params = $rootScope.toStateParams;
	                    if (to.name !== 'accessdenied') {
	                        Auth.storePreviousState(to.name, params);
	                    }
	                    var LoginService = $injector.get('LoginService');
	                    LoginService.open();
	            	}
        		}
            } else if (response.status === 403) {
            	if (to.name === 'login') {
            		$rootScope.addAlert('Bạn không có quyền truy cập hệ thống!', 'warning');
            	} else {
                    // If the CSRF token expired, then try to get a new CSRF token and retry the old request
                	$window.open(GLOBAL_URL,'_self'); 	
            	}           		
            }
            return $q.reject(response);
        }

        function getCSRF() {
            var doc = $document[0];
            if (doc) {
                var name = 'CSRF-TOKEN=';
                var ca = doc.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) === ' ') {c = c.substring(1);}

                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
            }
            return '';
        }

        function afterCSRFRenewed(oldResponse) {
            if (getCSRF() !== '') {
                // retry the old request after the new CSRF-TOKEN is obtained
                var $http = $injector.get('$http');
                return $http(oldResponse.config);
            } else {
                // unlikely get here but reject with the old response any way and avoid infinite loop
                return $q.reject(oldResponse);
            }
        }
        
        function getToken() {
        	var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token && token.expires_at && token.expires_at > new Date().getTime()) {
                return token.access_token;
            }
            return '';
        }
    }
})();
